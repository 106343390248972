(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('game')
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('petitionURL', "https://service.heroes.aiwin.co/")
        .constant('contentURL', "https://heroes.aiwin.co/content/")
        .constant('mediaURL', "https://heroes.aiwin.co/content/")
        .constant('staticURL', "https://heroes.aiwin.co/app/")
        .constant('config', {
	"mainButton": false,
	"devMode": false,
	"loglevel": "error",
	"login": {
		"type": "saml",
		"url": "https://mfbbva.csod.com"
	},
	"GACode": "UA-86292146-9"
})
        .constant('modules', {
	"common": "../../common/"
})
        .constant('tenant', "fmbbva")
;
})();
