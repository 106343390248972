angular.module('fmbbva', [
  'ngRoute',
  'game'
])
.config(['$sceDelegateProvider', '$routeProvider', '$httpProvider', 'mediaURL',
  function ($sceDelegateProvider, $routeProvider, $httpProvider, mediaURL) {

    // Example on how to override a template from game
    // $routeProvider.when('/results', {
    //   templateUrl: 'app/partials/results.html',
    //   title: ' Resumen Global '
    // });
    //$routeProvider.when('/', {
    //  templateUrl: 'app/partials/intro-page/intro-page.html',
    //  title: ' FMBBVA - Héroes'
    //});

  }
]).run(function(gameSettings) {

  // Overwrite game configuration
  gameSettings.video.autostart = false;
  gameSettings.video.skin = 'fmbbva';
  gameSettings.home.randomSelection = false;
  gameSettings.intro.videoResponsive = false;
  gameSettings.settings.ranking.userLogo = 'images/common/icons/icon-user-48x48.png';
  gameSettings.settings.site.logo = 'images/common/v2/main/project-361x62.png';
  gameSettings.settings.site.name = 'Héroes';
  gameSettings.settings.client.logo = 'images/common/v2/main/client-321x65.png';
  gameSettings.settings.client.name = 'FMBBVA';
  gameSettings.settings.profile.statisticColor1 = '#009EE5';
  gameSettings.settings.profile.statisticColor2 = '#1F3B72';
  gameSettings.video.subtitles.active = false;
  gameSettings.video.subtitles.autostart = false;
  gameSettings.login.redirectToChallenge = false;
  gameSettings.video.fullscreen.active = true;
});
